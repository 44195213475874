import {combineReducers} from 'redux';

import chats from "../states/chats";
import profile from "../states/profile";

const rootReducer = combineReducers({
	chats,
	profile
});

export default rootReducer
