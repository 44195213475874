import agent from "../agent/agent";

const GET_CHATS = "chats/GET_CHATS";
const RESET_CHATS = "chats/RESET_CHATS";
const CHANGE_LOADING = "chats/CHANGE_LOADING";

const initialState = {
  chats: [],
  isLoading: true,
};

export function getChats () {
  return async function (dispatch) {
    dispatch({type: CHANGE_LOADING, value: true});
    const res = await agent.get('/api/v1/interactions').then((res) => {
      return res.data
    }).catch(() => {
      return []
    });
    dispatch({
      type: GET_CHATS,
      chats: res || [],
    })
  }
}
export function resetChats () {
  return {
    type: RESET_CHATS,
  }
}

export function createChat (name) {
  return async function (dispatch) {
    dispatch({ type: CHANGE_LOADING, value: true });
    const res = await agent.post('/api/v1/interactions/create', {
      chat_name: name
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    dispatch(getChats());
    dispatch({ type: CHANGE_LOADING, value: false });

    return res
  }
}
export function removeChat (chatId) {
  return async function (dispatch) {
    dispatch({ type: CHANGE_LOADING, value: true });
    const res = await agent.delete(`/api/v1/interactions/delete/${ chatId }`).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    await dispatch(getChats());
  }
}
export function changeChatName (id, body) {
  return async function (dispatch) {
    const res = await agent.patch(`/api/v1/interactions/update/${ id }`, body).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    await dispatch(getChats());
  }
}

export default function GlobalState(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CHATS: {
      return {
        ...state,
        chats: action.chats,
        isLoading: false
      }
    }
    case CHANGE_LOADING: {
      return {
        ...state,
        isLoading: action.value
      }
    }
    case RESET_CHATS: {
      return {
        ...state,
        chats: [],
        isLoading: true,
      }
    }

    default:
      return state;
  }
}
