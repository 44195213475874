import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Cabinet as CabinetLayout
} from "../layouts";
import {
  Home as HomePage,
  ChatId as ChatIdPage,
  Profile as ProfilePage
} from "../pages";

const pages = [
  {
    path: '/',
    component: HomePage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/chat/:id',
    component: ChatIdPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/profile',
    component: ProfilePage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
];

const AuthRoute = () => {
  return (
    <Switch>
      {
        pages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/"/>
    </Switch>
  );
};

export default AuthRoute;
