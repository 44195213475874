import {palette} from "../common";

export default {
  styleOverrides: {
    label: {
      fontWeight: "500",
      fontSize: 16,
      lineHeight: "24px",
      textDecorationLine: "underline",
      color: palette.black
    }
  }
}
