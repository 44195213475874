import React from "react";
import {
	Box,
	CircularProgress
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const LoadingContent = () => {
	const classes = useStyles();
	return (
		<Box className={`background-pages background-authorization ${classes.root}`}>
			<img src={require("../../assets/image/logo/logo-small.png")}/>
			<CircularProgress sx={{ color: "#176acc" }}/>
		</Box>
	)
}

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100vh",
	}
}))

export default LoadingContent
