import React, {Component} from "react";
import {
	Box,
	Tab,
	Grid,
	Tabs,
	Button,
	Select,
	Tooltip,
	Divider,
	Backdrop,
	MenuItem,
	Container,
	TextField,
	Typography,
	FormControl,
	CircularProgress
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
import {
	Help as HelpIcon
} from "@mui/icons-material";
import {
	Formik
} from "formik";
import {
	palette
} from "../../../theme/common";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import {
	DatePicker
} from "@mui/x-date-pickers/DatePicker";
import {
	IMaskInput
} from "react-imask";
import {
	BackdropLoading
} from "../ChatId/components";
import * as Yup from "yup";
import moment from "moment";
import agent from "../../../agent/agent";
import {textToMask} from "../../../helpers/masked";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import clsx from "clsx";
import copyToClipboard from "../../../helpers/copyToClipboard";

const initForm = {
	first_name: "",
	last_name: "",
	phone: "",
	gender: "",
	birth_date: "",
};

class Profile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {...initForm},
			activeTab: "settings",
			isInitForm: false,
			isBackdrop: false
		};
		this.refFormik = React.createRef();
	}

	componentDidMount = () => {
		this.initForm();
	}

	initForm = () => {
		const {profile} = this.props;
		delete profile.user_id;
		delete profile.created_at;
		delete profile.updated_at;
		profile.phone = Boolean(profile.phone) ? textToMask((profile.phone || '').slice(2), '+7 (###) ### ## ##') : "";

		const form = {
			...initForm,
			...profile,
			birth_date: moment(profile.birth_date)
		}
		this.setState({
			form,
			isInitForm: true
		})
	}
	changeForm = ({target}) => {
		const {name, value} = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}
	submitForm = async (form) => {
		this.setState({isBackdrop: true})
		let body = {...form};
		if (!body.gender) delete body.gender;
		body.phone = `+${(body.phone || "").replace(/\D+/g, "")}`;
		const res = await agent.put(`/api/v1/accounts/profile`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res.error) {
			this.setState({isBackdrop: false});
			Notification({
				type: notificationTypes.error,
				message: res?.error?.data?.message || "Ошибка сервера"
			})
			return
		}
		await this.props.authGetMe();
		this.setState({isBackdrop: false});
		Notification({
			type: notificationTypes.success,
			message: "Профиль успешно обновлен"
		})
	}

	sendVerificationEmail = async () => {
		this.setState({isBackdrop: true});
		const res = await agent.post(`/accounts/email/verification`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response?.data?.message || "Ошибка сервера"}
		});
		if (res.error) {
			this.setState({isBackdrop: false});
			Notification({
				type: notificationTypes.error,
				message: res.error
			})
			return
		}
		this.setState({isBackdrop: false});
		Notification({
			type: notificationTypes.success,
			message: "Письмо для подтверждения отправлено на почту"
		});
	}

	render() {
		const {
			classes,
			account
		} = this.props;
		const {
			form,
			activeTab,
			isBackdrop,
			isInitForm
		} = this.state;


		return (
			<Container style={{maxWidth: 720}}>
				<Box className={classes.root}>
					<Box className={classes.header}>
						<Typography variant="h1" className={classes.title}>Профиль</Typography>
						<Tabs value={activeTab} className={classes.tabs} centered onChange={(e, activeTab) => this.setState({activeTab})}>
							<Tab label="Настройки" value="settings"/>
							<Tab label="Тарифы" value="tariff" to="/" component={Link}/>
							{/*<Tab label="Промо" value="ref-programm"/>*/}
						</Tabs>
					</Box>
					<Box className={classes.body}>
						<VisibleContent visible={Boolean(activeTab === "settings")}>
							{Boolean(isInitForm) && (
								<Formik
									innerRef={this.refFormik}
									initialValues={form}
									validationSchema={validationSchema}
									onSubmit={this.submitForm}
								>{(props) => {
									const {
										values,
										touched,
										errors,
										handleSubmit
									} = props;

									return (
										<Grid container spacing="16px">
											<Grid item xs={12}>
												<FormElement
													value={values.last_name}
													name="last_name"
													title="Фамилия"
													placeholder="Иванов"
													error={Boolean(touched.last_name && errors.last_name)}
													helperText={touched.last_name && errors.last_name}
													onChange={this.changeForm}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormElement
													value={values.first_name}
													name="first_name"
													title="Имя"
													placeholder="Иван"
													error={Boolean(touched.first_name && errors.first_name)}
													helperText={touched.first_name && errors.first_name}
													onChange={this.changeForm}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormElement
													value={values.phone}
													name="phone"
													title="Телефон"
													placeholder="+7 (000) 000 00 00"
													error={Boolean(touched.phone && errors.phone)}
													helperText={touched.phone && errors.phone}
													InputProps={{inputComponent: CustomInputPhone}}
													onChange={this.changeForm}
												/>
											</Grid>
											<Grid item xs={12}>
												<Button variant="contained" fullWidth onClick={handleSubmit}>
													Сохранить изменения
												</Button>
											</Grid>
										</Grid>
									)
								}}</Formik>
							)}
						</VisibleContent>
						<VisibleContent visible={Boolean(activeTab === "tariff")}>
							<SubscriptionsSection/>
						</VisibleContent>
						<VisibleContent visible={Boolean(activeTab === "ref-programm")}>
							<ReferralProgram/>
						</VisibleContent>
					</Box>
				</Box>
				<BackdropLoading open={isBackdrop}/>
			</Container>
		)
	}
}

const VisibleContent = React.memo(({children, visible}) => {
	return (<Box style={{display: visible ? 'block' : 'none'}}>{children}</Box>)
})
const InformationNoVerification = React.memo((props) => {
	const {
		classes,
		onSend
	} = props;

	return (
		<Box className={classes.informationNoVerification}>
			<Typography className="title">Ваш профиль не подтвержден.</Typography>
			<Typography className="button" onClick={onSend}>Отправить подтверждение на почту</Typography>
		</Box>
	)
})
const SubscriptionsSection = React.memo(() => {
	const subscriptions = useSelector(context => context?.global?.subscriptions);

	if ((subscriptions || []).length <= 0) {
		return (
			<>
				<Typography variant="h4" color="text.primary" textAlign="center">Нет активный тарифов</Typography>
				<Box mt={2} display="flex" justifyContent="center">
					<Button variant="contained" component={Link} to={`/tariff`}>
						Купить тариф
					</Button>
				</Box>
			</>
		)
	}
	return (
		<Grid container spacing={2}>
			{(subscriptions || []).map((item, index) => (
				<Grid item xs={12}>
					<TariffCard
						item={item}
					/>
				</Grid>
			))}
			<Grid item xs={12}>
				<Box mt={2} display="flex" justifyContent="center">
					<Button variant="contained" component={Link} to={`/tariff`}>
						Купить тариф
					</Button>
				</Box>
			</Grid>
		</Grid>
	)
});
const ReferralProgram = React.memo(() => {
	const classes = useStyles();
	const referral = useSelector(state => state?.global?.accountProfile?.referral?.referral);
	const urlLink = [window.location.origin, `?referral=${ referral }`].join("");

	const copyLink = () => {
		copyToClipboard(urlLink);
		Notification({
			type: notificationTypes.success,
			message: "Ссылка успешно скопирована"
		})
	}

	return (
		<Box className={classes.refProgrammCode}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography className={classes.refProgrammCodeTitle}>Ваша реферальная ссылка</Typography>
					<Typography className={classes.refProgrammCodeLink}>{ urlLink }</Typography>
					<Button onClick={copyLink} color="primary" variant="contained" className={classes.refProgrammCodeButton}>Копировать</Button>
				</Grid>
			</Grid>
		</Box>
	)
});

const validationSchema = Yup.object().shape({
	first_name: Yup.string().required('Заполните поле'),
	last_name: Yup.string().required('Заполните поле'),
	phone: Yup.string().required('Заполните поле').length(18, 'Некорректный номер'),
});
const FormElement = React.memo((props) => {
	const {
		title,
		placeholder,
		helpMessage,

		...otherProps
	} = props;
	const classes = useStyles();

	return (
		<Box>
			<Grid container justifyContent="space-between">
				<Grid item>
					<Typography variant="formLabel" mb="8px">{ title }</Typography>
				</Grid>
				{Boolean(helpMessage) && (
					<Grid item>
						<Typography variant="formLabel" mb="8px" className={classes.buttonHelp} style={{color: palette.text.lightGray}}>{ helpMessage }</Typography>
					</Grid>
				)}
			</Grid>
			<TextField
				placeholder={placeholder}
				fullWidth
				{...otherProps}
			/>
		</Box>
	)
});
const CustomInputPhone = ({inputRef, ...rest}) => {
	const onComplete = (value) => {
		rest.onChange({
			target: {
				name: rest.name,
				value
			}
		})
	}
	return (
		<IMaskInput
			ref={inputRef}
			mask="+7 (000) 000 00 00"
			thousandsSeparator=""
			padFractionalZeros={false}
			{...rest}
		/>
	)
};

const styles = {
	root: {
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		background: "#FFF",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
		position: "relative",
		overflow: "hidden"
	},

	header: {
		padding: "32px 0 0",
		boxSizing: "border-box"
	},
	title: {
		marginBottom: "15px",

		fontSize: 24,
		lineHeight: "120%",
		textAlign: "center",
		color: palette.primary.text
	},
	tabs: {
		borderBottom: "1px solid rgba(0,0,0,0.1)",

		"& .MuiButtonBase-root": {
			textTransform: "initial",
			fontSize: "16px",
			lineHeight: "20px",
			color: "#99ACC3",
			letterSpacing: "-0.32px"
		},
		"& .Mui-selected": {
			color: "#126DDE"
		},
		"& .MuiTabs-indicator": {
			height: 3,
			borderRadius: "100px",
			background: "#126DDE"
		}
	},

	body: {
		padding: 24,
		boxSizing: "border-box"
	},

	informationNoVerification: {
		display: "flex",
		flexDirection: "column",
		padding: "28px",
		boxSizing: "border-box",
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		background: "linear-gradient(90deg, #126DDE 0%, #9E22FF 100%)",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
		marginBottom: 12,

		"& .title": {
			marginBottom: 20,

			fontSize: 26,
			lineHeight: "120%",
			textAlign: "center",
			color: "white",
			fontWeight: "500"
		},
		"& .button": {
			margin: "0 auto",
			border: "1px solid",
			borderRadius: "8px",
			padding: "8px 12px",
			background: "rgba(255,255,255,0.2)",
			fontSize: 18,
			lineHeight: "120%",
			color: "white",
			letterSpacing: "-0.36px",
			fontWeight: "500",
			cursor: "pointer",
			textAlign: "center",

			"&:hover": {
				background: "rgba(255,255,255,1)",
				color: "#005BCB"
			}
		},
	}
};
Profile = withStyles(styles)(Profile);

const TariffCard = React.memo((props) => {
	const {
		item
	} = props;
	const classes = useStyles();
	const [tariff, setTariff] = React.useState(null);
	const isEnded = Boolean(moment(item.ends_at).diff(moment(), 'day') <= 0);
	const isLessWeek = Boolean(moment(item.ends_at).diff(moment(), 'day') <= 3);

	React.useEffect(() => {
		(async () => {
			await getTariff();
		})();
	}, []);

	const getTariff = async () => {
		const res = await agent.get(`/tariffs/${item.tariff_id}`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		});
		setTariff(res);
	}
	const _limits = () => {
		const isGpt4 = Boolean(item.can_gpt4);
		const used = item?.limits?.used;
		const limit = item?.limits?.limit;

		let workText = (limit?.scenario || 0) - (used?.scenario || 0);
		let workTextClass = '--success';
		if (workText <= 0) {
			workText = 0;
			workTextClass = '--error'
		}

		let workImage = (limit?.midjourney || 0) - (used?.midjourney || 0);
		let workImageClass = '--success';
		if (workImage <= 0) {
			workImage = 0;
			workImageClass = '--error';
		}

		const canGpt4Class = Boolean(item?.can_gpt4 || false) ? '--success' : '--error';
		if (isGpt4) {
			const available = Math.floor(workText / 50);
			const totalLimit = Math.floor(limit?.scenario / 50);
			return `
      Работа с текстом: GPT4-o Mini - <span class="${workTextClass}">${workText}/${(limit?.scenario || 0)}</span> или GPT4-o  - <span class="${workTextClass}">${available}/${(totalLimit || 0)}</span><br/> 
      Работа с изображением: <span class="${workImageClass}">${workImage}/${(limit?.midjourney || 0)}</span><br/>
      GPT4-o: <span class="${canGpt4Class}">${Boolean(item?.can_gpt4 || false) ? 'Да' : 'Нет'}</span>
    `
		}
		return `
      Работа с текстом: <span class="${workTextClass}">${workText}/${(limit?.scenario || 0)}</span><br/> 
      Работа с изображением: <span class="${workImageClass}">${workImage}/${(limit?.midjourney || 0)}</span><br/>
      GPT4-o: <span class="${canGpt4Class}">${Boolean(item?.can_gpt4 || false) ? 'Да' : 'Нет'}</span>
    `
	}
	const _helpMessage = () => {
		if (isEnded) {
			return "Период работы вашего тарифа закончился, вы можете купить новый тариф."
		}
		if (isLessWeek) {
			return "Период работы вашего тарифа подходит к концу, вы можете купить новый тариф."
		}
	}

	return (
		<Box className={clsx({
			[classes.tariffCard]: true,
			'ended': isEnded,
			'will-end-soon': isLessWeek,
		})}>
			<Typography className={classes.tariffCardName}>{tariff?.name}</Typography>
			<Typography
				className={classes.tariffCardLimits}
				dangerouslySetInnerHTML={{__html: _limits()}}
			/>
			<Typography className={classes.tariffCardDuration}>
				Активен до <span>{moment(item.ends_at).format("DD.MM.YYYY HH:mm:ss")}</span>
			</Typography>

			<VisibleContent visible={Boolean(isEnded || isLessWeek)}>
				<Tooltip title={_helpMessage()} arrow>
					<Box className={classes.tariffCardHelp}>
						<HelpIcon color="primary"/>
					</Box>
				</Tooltip>
			</VisibleContent>
		</Box>
	)
});
const useStyles = makeStyles(() => ({
	tariffCard: {
		padding: "18px",
		boxSizing: "border-box",
		background: '#FFF',
		borderRadius: '23px',
		border: '0.5px solid rgba(0, 0, 0, 0.10)',
		boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',
		position: "relative",

		"&.ended": {
			outline: "1px solid #FB488B",
			borderColor: "#FB488B"
		},
		"&.will-end-soon": {
			outline: "1px solid #F6C800",
			borderColor: "#F6C800"
		},
	},
	tariffCardName: {
		color: '#334A67',
		fontSize: '16px',
		lineHeight: '18px',
		fontStyle: 'normal',
		fontWeight: '500',
		letterSpacing: '0.6px',
		textTransform: 'uppercase',
	},
	tariffCardLimits: {
		marginTop: 6,

		color: '#334A67',
		fontSize: '14px',
		lineHeight: '18px',
		fontStyle: 'normal',
		fontWeight: '400',
		letterSpacing: '0.6px',
		textTransform: 'uppercase',

		"& span": {
			fontWeight: "500"
		},
		"& .--success": {
			color: "#94D340"
		},
		"& .--warning": {
			color: "#F6C800"
		},
		"& .--error": {
			color: "#DE125B"
		},
	},
	tariffCardDuration: {
		marginTop: 6,

		color: '#334A67',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: 'normal',
		letterSpacing: '0.4px',

		"& span": {
			fontWeight: "500"
		}
	},
	tariffCardHelp: {
		position: "absolute",
		right: "5px", bottom: "5px"
	},

	refProgrammCode: {
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		background: "rgba(255, 255, 255, 0.10)",
		boxShadow: "-1px 5px 12px 0px rgba(15, 68, 138, 0.05), -3px 21px 22px 0px rgba(15, 68, 138, 0.04), -7px 48px 29px 0px rgba(15, 68, 138, 0.03), -13px 85px 35px 0px rgba(15, 68, 138, 0.01), -20px 134px 38px 0px rgba(15, 68, 138, 0.00)",
		backdropFilter: "blur(80px)",
		padding: "20px",
		boxSizing: "border-box"
	},
	refProgrammCodeTitle: {
		marginBottom: "15px",

		color: "#99ACC3",
		fontSize: "14px",
		lineHeight: "116%",
		letterSpacing: "-0.32px",
	},
	refProgrammCodeInfo: {
		color: "#254164",
		fontSize: "16px",
		lineHeight: "116%",
		letterSpacing: "-0.32px",

		"& span": {
			fontWeight: "500",
			color: "#2E8BFF"
		}
	},
	refProgrammCodeLink: {
		color: "#254164",
		fontSize: "13px",
		fontWeight: "500",
		lineHeight: "130%",
		letterSpacing: "-0.48px",
		whiteSpace: "nowrap"
	},
	refProgrammCodeButton: {
		width: "100%",
		padding: "4px",
		borderRadius: "4px",
		marginTop: "10px",
		fontSize: "14px",
	},
}));

export default Profile
