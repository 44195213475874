import React, {Component} from "react";
import {
	Grid,
	Portal,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	ListChats,
	VisibleContent,
	DialogConfirmation,
} from "../../../components";
import {
	Header,
	ChatHistory,
	DialogChange,
	EmptyContent,
	FooterAction,
	BackdropLoading,
	DialogSelectedFiles
} from "./components";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";

class ChatId extends Component {
	constructor(props) {
		super(props);

		this.state = {
			history: [],
			fileUrl: "",
			chatId: null,
			selectedFiles: null,
			isLoading: true,
			isBackdrop: false,
			isBackdropFiles: false,
		};
		this.refDialogChange = React.createRef();
		this.refDialogConfirmation = React.createRef();
		this.refDialogSelectedFiles = React.createRef();
	}

	componentDidMount = async () => {
		await this.initState();
	}
	componentDidUpdate = async (prevProps) => {
		const prevId = prevProps.match.params.id;
		const currentId = this.props.match.params.id;
		if (prevId !== currentId) {
			await this.initState();
		}
	}

	initState = async () => {
		await this.setState({
			chatId: this.props.match.params.id,
			history: [],
			fileUrl: "",
			selectedFiles: null,
		})
		await this.getChat();
		await this.getHistory();
	}
	getHistory = async () => {
		const {chatId} = this.state;
		const res = await agent.get(`/api/v1/interactions/${chatId}`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});
		this.setState({
			history: [...res]
		})
	}

	getChat = async () => {
		// const res = await agent.get('')
	};
	changeChat = async (body) => {
		if (!body) {
			this.refDialogChange.current.open({
				onSubmit: this.changeChat.bind(null)
			})
			return
		}
		await this.setState({isBackdrop: true});
		const {chatId} = this.state;
		await this.props.changeChatName(chatId, body);
		this.refDialogChange.current.close();
		await this.setState({isBackdrop: false});
	};
	removeChat = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.open({
				message: `Вы действительно хотите удалить чат?`,
				acceptButtonTitle: "Да, удалить",
				onSubmit: this.removeChat.bind(null, true)
			});
			return
		}
		await this.setState({isBackdrop: true});
		await this.props.removeChat(this.state.chatId);
		await this.props.history.push("/");
	};

	sendFilesToChat = async (selectedFiles) => {
		if (!selectedFiles) {
			this.refDialogSelectedFiles.current.open({
				onSubmit: this.sendFilesToChat.bind(null)
			})
			return
		}
		this.setState({isBackdropFiles: true});

		const {chatId} = this.state;
		let actionUrl = `/api/v1/interactions/upload/image/${chatId}`;
		if (selectedFiles.type === 'file') {
			actionUrl = `/api/v1/interactions/upload/file/${chatId}`;
		}

		const formData = new FormData();
		(selectedFiles.file || []).map((file) => {
			formData.append("files", file);
		});

		const resPostFiles = await agent.post(actionUrl, formData).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response?.data?.message}
		});
		if (resPostFiles?.error) {
			await this.setState({
				isBackdropFiles: false
			});
			Notification({
				message: resPostFiles?.error || "Ошибка сервера",
				type: notificationTypes.error
			})
			return
		}

		const fileUrl = resPostFiles?.url_id;

		let notLoadFiles = [];
		let successLoadFiles = [];
		(resPostFiles?.file_paths || []).map((file) => {
			if (!Boolean(file?.status)) {
				notLoadFiles.push(file?.file_name)
			} else {
				successLoadFiles.push(file?.file_name)
			}
		});
		if (notLoadFiles.length > 0) {
			Notification({
				message: `Ошибка загрузки следующих файлов:<br>${notLoadFiles.join(';<br/>')}`,
				type: notificationTypes.error,
				duration: 100000
			})
		}

		await this.props.getChats();

		let history = [
			...(this.state.history),
			{
				user_message: "",
				assistant_message: "",
				file_names: successLoadFiles,
				isOnlyFiles: true
			}
		];

		this.refDialogSelectedFiles.current.close();
		await this.setState({
			history: history,
			fileUrl: fileUrl || "",
			isBackdropFiles: false
		});
	};
	sendNewQuestion = async (question) => {
		this.setState({isBackdrop: true});
		const {chatId, fileUrl} = this.state;
		const body = {
			url_id: fileUrl,
			user_message: question
		};
		if (!body.url_id) {
			delete body.url_id
		}
		const res = await agent.post(`/api/v1/interactions/request/${chatId}`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		await this.getHistory();
		this.setState({
			fileUrl: "",
			isBackdrop: false
		});
	};

	render() {
		const {
			chats
		} = this.props;
		const {
			chatId,
			fileUrl,
			history,
			isBackdrop,
			selectedFiles,
			isBackdropFiles
		} = this.state;
		const chat = chats.find((t) => Boolean(t.id === chatId));
		const chatType = chat?.type || "";

		return (
			<>
				<Grid container spacing={4}>
					<Grid item xs={3}>
						<ListChats/>
					</Grid>
					<Grid item xs={9} sx={{display: "flex", flexDirection: "column", minHeight: "calc(100vh - 70px)"}}>
						<Header
							name={chat?.chat_name || ""}
							file={selectedFiles}
							onChange={this.changeChat.bind(null, "")}
							onRemove={this.removeChat.bind(null, false)}
						/>
						<VisibleContent visible={Boolean(history.length <= 0)}>
							<EmptyContent/>
						</VisibleContent>
						<VisibleContent visible={Boolean(history.length > 0)}>
							<ChatHistory items={history}/>
						</VisibleContent>
						<FooterAction
							disabledSelect={true || Boolean(fileUrl)}
							onSelect={this.sendFilesToChat.bind(null, null)}
							onSendNewQuestion={this.sendNewQuestion}
						/>
					</Grid>

					<DialogChange ref={this.refDialogChange}/>
					<DialogConfirmation ref={this.refDialogConfirmation}/>
					<DialogSelectedFiles
						ref={this.refDialogSelectedFiles}
						chatType={chatType}
					/>
				</Grid>
				<BackdropLoading
					open={isBackdropFiles || isBackdrop}
					message={isBackdropFiles && 'Идет загрузка документа<br/>Подождите...'}
				/>
			</>
		);
	}
}

export default ChatId
