import React from "react";
import {
	Box,
	Grid,
	Button,
	FormLabel,
	TextField,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";

const initForm = {
	chat_name: ""
};

class DialogChange extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			form: {...initForm},
			isOpen: false,
			onSubmit: null
		};
	}

	open = ({ onSubmit }) => {
		this.setState({
			form: {...initForm},
			isOpen: true,
			onSubmit,
		})
	}
	close = () => {
		this.setState({
			form: {...initForm},
			isOpen: false,
			onSubmit: null,
		})
	}

	change = ({ target }) => {
		const { name, value } = target;
		let form = {...this.state.form};
		form[name] = value;
		this.setState({ form })
	}
	submit = () => {
		this.state.onSubmit(this.state.form);
	}

	render () {
		const {
			form,
			isOpen
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">Изменение чата</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormLabel sx={{ marginBottom: 1.5 }}>Наименование чата</FormLabel>
							<TextField
								vlaue={form.chat_name}
								fullWidth={true}
								name="chat_name"
								variant="outlined"
								placeholder="Введите новое название чата"
								onChange={this.change}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container spacing={1} justifyContent="flex-end">
						<Grid item>
							<Button
								size="small"
								variant="outlined"
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item>
							<Button
								size="small"
								variant="contained"
								disabled={!form.chat_name}
								onClick={this.submit}
							>Изменить</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DialogChange
