import axios from 'axios';
import errorCodes from "../constants/error-codes.json";
import {getDynamicDomain} from "../helpers/getBaseDomain";

const axiosInstance = axios.create({
  baseURL: getDynamicDomain({ baseDomain: process.env.REACT_APP_HOST_API }),
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
  }
});
axiosInstance.interceptors.response.use((response) => response, (error) => {
  const errorData = error?.response?.data;
  const errorCode = error?.response?.data?.code;
  if (!errorCode || !errorCodes[errorCode]) {
    throw error;
  }

  let message = errorCodes[errorCode];
  if (errorCode === 5204) {
    const _info = errorData?.params?.[0];
    message = message.replaceAll('{{in_request}}', _info.in_request);
    message = message.replaceAll('{{limit}}', _info.limit);
  }

  error.response.data.message = message;
  throw error;
});


export default axiosInstance
