import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      borderRadius: "12px",
      fontSize: 14,
      lineHeight: "16px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
      },
      "& .MuiInputBase-input": {
        padding: "9px 14px",
      }
    },
    multiline: {
      padding: "0!important"
    },

    input: {
      borderRadius: "12px!important",
      border: "0.5px solid rgba(0, 0, 0, 0.10)",
      background: "#FFF",
      boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",

      fontSize: 14,
      lineHeight: "16px",
    }
  }
}
