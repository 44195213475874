// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Registration from './Registration';
import {authLogin} from "../../../states/profile";

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      authLogin: (body) => dispatch(authLogin(body))
    }),
  ),
)(Registration);
