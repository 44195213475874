export default {
  styleOverrides: {
    root: {
      marginTop: 3,
      marginLeft: 14,

      fontSize: 14,
      lineHeight: '19px',
      color: '#F5386A',
      justifyContent: 'flex-start',
    }
  }
}
