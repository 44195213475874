import React from "react";
import {
	Box,
	Container
} from "@mui/material";

const Authorization = (props) => {
	return (
		<Box className="background-pages background-authorization">
			<Container>
				{props.children}
			</Container>
		</Box>
	)
}

export default React.memo(Authorization)
