import React from "react";
import {
	Box,
	Grid,
	Button,
	FormLabel,
	Container,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions, ToggleButtonGroup, ToggleButton,
} from "@mui/material";
import {} from "@mui/styles";
import {
	FilePicker
} from "../../../../../components";

const initForm = {
	file: []
};

class DialogSelectedFiles extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {...initForm},
			type: "file",
			isOpen: false,
			onSubmit: null
		};
	}

	open = ({onSubmit}) => {
		let type = 'file';
		if (this.props.chatType === 'chat') {
			type = 'image'
		}

		this.setState({
			form: {...initForm},
			isOpen: true,
			type,
			onSubmit
		})
	}
	close = () => {
		this.setState({
			form: {...initForm},
			isOpen: false,
			onSubmit: null
		})
	}

	changeType = (event, value) => {
		this.setState({type: value})
	}
	changeFile = ({target}) => {
		let form = {...this.state.form};

		let files = [
			...(form.file),
			...(target.value || [])
		].splice(0, 5);
		form.file = files;

		this.setState({form});
	};
	removeFile = (index) => {
		let form = {...this.state.form};
		let file = [...(form?.file || [])];
		file.splice(index, 1);
		form.file = file;
		this.setState({ form })
	}

	submit = () => {
		const {
			type,
			form
		} = this.state;
		this.state.onSubmit({
			type: type,
			file: form.file
		});
	}

	render() {
		const {
			form,
			type,
			isOpen
		} = this.state;
		const {
			chatType
		} = this.props;
		const isVisibleType = Boolean(chatType === 'empty');

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="lg"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3" textAlign="left">Выбор файлов</Typography>
				</DialogTitle>
				<DialogContent>
					<Box mb={2}>
						<ToggleButtonGroup
							value={type}
							color="primary"
							disabled={!isVisibleType}
							exclusive={true}
							onChange={this.changeType}
						>
							<ToggleButton value="file">Файл</ToggleButton>
							<ToggleButton value="image">Изображение</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Box>
						<FilePicker
							value={form.file}
							message="Перетащите сюда файлы (не более 5)"
							accept={Boolean(type === 'file') ? {
								'text/plain': [".csv", ".c", ".cs", ".cpp", ".doc", ".docx", ".html", ".java", ".json", ".md", ".pdf", ".php", ".pptx", ".py", ".rb", ".tex", ".txt", ".css", ".js", ".sh", ".ts", ".xlsx", ".xlsm", ".xltm", ".xltx", ".xlam"],
								'application/pdf': [".csv", ".c", ".cs", ".cpp", ".doc", ".docx", ".html", ".java", ".json", ".md", ".pdf", ".php", ".pptx", ".py", ".rb", ".tex", ".txt", ".css", ".js", ".sh", ".ts", ".xlsx", ".xlsm", ".xltm", ".xltx", ".xlam"],
								'application/msword': [".csv", ".c", ".cs", ".cpp", ".doc", ".docx", ".html", ".java", ".json", ".md", ".pdf", ".php", ".pptx", ".py", ".rb", ".tex", ".txt", ".css", ".js", ".sh", ".ts", ".xlsx", ".xlsm", ".xltm", ".xltx", ".xlam"],
								'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [".csv", ".c", ".cs", ".cpp", ".doc", ".docx", ".html", ".java", ".json", ".md", ".pdf", ".php", ".pptx", ".py", ".rb", ".tex", ".txt", ".css", ".js", ".sh", ".ts", ".xlsx", ".xlsm", ".xltm", ".xltx", ".xlam"],
							} : {
								'image/*': ['.png', '.jpeg', '.jpg', '.webp']
							}}
							onChange={this.changeFile}
							onRemove={this.removeFile}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Grid container spacing={1} justifyContent="flex-end">
						<Grid item>
							<Button
								size="small"
								variant="outlined"
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item>
							<Button
								size="small"
								variant="contained"
								onClick={this.submit}
							>Отправить</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DialogSelectedFiles
