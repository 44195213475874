import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	TextField,
	IconButton,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	AttachFile as AttachFileIcon
} from "@mui/icons-material";
import {
	VisibleContent
} from "../../../../../components";

const FooterAction = (props) => {
	const {
		disabledSelect,

		onSelect,
		onSendNewQuestion
	} = props;
	const classes = useStyles();

	const [question, setQuestion] = React.useState("");
	const handleChangeQuestion = ({target}) => {
		const {value} = target;
		setQuestion(value);
	};
	const handleSendQuestion = () => {
		if (question.length <= 0) {
			return null
		}

		onSendNewQuestion(question);
		setQuestion("");
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSendQuestion();
		}
	}

	return (
		<Box className={classes.root}>
			<Grid container spacing={2}>
				<VisibleContent visible={!disabledSelect}>
					<Grid item>
						<Tooltip title="Прикрепить файлы" arrow>
							<Button
								color="primary"
								sx={{padding: "7px 20px"}}
								onClick={onSelect}
							>
								<AttachFileIcon sx={{fontSize: 24}}/>
								Прикрепить файл
							</Button>
						</Tooltip>
					</Grid>
				</VisibleContent>
				<VisibleContent visible={disabledSelect}>
					<Grid item>
						<Tooltip title="Прикрепить файлы" arrow>
							<IconButton
								color="primary"
								onClick={onSelect}
							>
								<AttachFileIcon sx={{fontSize: 24}}/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item sx={{flex: "auto"}}>
						<TextField
							value={question}
							fullWidth
							variant="outlined"
							placeholder="Введите ваш вопрос"
							onKeyDown={handleKeyDown}
							onChange={handleChangeQuestion}
						/>
					</Grid>
					<Grid item>
						<Button
							size="small"
							variant="contained"
							disabled={!question}
							className={classes.buttonSubmit}
							onClick={handleSendQuestion}
						>Отправить</Button>
					</Grid>
				</VisibleContent>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px 24px",
		boxSizing: "border-box",
		background: "white",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		marginTop: 10,
		borderRadius: 16
	},
	buttonSubmit: {
		height: 40,
		fontSize: 14,
		lineHeight: 1,
		borderRadius: 10,
		padding: "8px 20px"
	}
}));

export default FooterAction
