
export default {
	styleOverrides: {
		root: {
      fontSize: 15,
      lineHeight: "160%",
      top: 0,
      color: "#647083",
      transform: 'translate(16px, 10px) scale(1)!important',
      display: 'flex!important',
      alignItems: 'center',

      "&.Mui-focused": {
        transform: 'translate(10px, -10px) scale(1)!important',
        height: 'auto',
        padding: '2px 6px',
        background: 'white',
        lineHeight: '100%',
        borderRadius: '4px',
      },
      "&.MuiInputLabel-shrink": {
        transform: 'translate(10px, -10px) scale(1)!important',
        height: 'auto',
        padding: '2px 6px',
        background: 'white',
        lineHeight: '100%',
        borderRadius: '4px',
      }
    }
	}
}
