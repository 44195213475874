import React from "react";
import {
	Box,
	Grid,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const EmptyContent = (props) => {
	const {} = props;
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<img src={require("../../../../../assets/image/logo/logo-small.png")} className={classes.icon}/>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography className={classes.message}>
						Чтобы работать с документом, нажмите на скрепку в поле ниже.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className={classes.message}>
						Для работы в режиме чата просто задайте вопрос в поле ниже и нажмите кнопку <span>«Отправить»</span>
					</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		padding: "32px 64px",
		boxSizing: "border-box",

		width: "100%",
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",

		backgroundImage: "radial-gradient(rgb(0 0 0 / 5%) 20%, transparent 20%), radial-gradient(#fafafa 20%, transparent 20%)",
		backgroundColor: "#ffffff",
		backgroundPosition: "0 0, 150px 150px",
		backgroundSize: "5px 5px",
	},
	icon: {
		maxWidth: 240,
		margin: "0 auto",
		marginBottom: 32
	},
	message: {
		fontSize: 16,
		lineHeight: 1.4,
		color: "#99ACC3",
		fontWeight: "500",
		"& span": {
			color: "#0F7AFF"
		}
	}
}));
EmptyContent.defaultProps = {
	hideMessage: false
}

export default React.memo(EmptyContent)
