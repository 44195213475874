const getDynamicDomain = ({ baseDomain, port }) => {
	const location = window.location;
	const locationHostname = location?.hostname;

	if (process.env.NODE_ENV === "development" || locationHostname === '10.7.33.46') {
		return baseDomain
	}
	if (!locationHostname) {
		return baseDomain
	}

	return `${ location.protocol }//${ locationHostname }`
}
const getDynamicDomainSocket = ({ baseDomain, port, protocol }) => {
	if (process.env.NODE_ENV === "development") {
		return baseDomain
	}

	const location = window.location;
	const locationHostname = location?.hostname;
	if (!locationHostname) {
		return baseDomain
	}

	return `${ protocol }//${ locationHostname }:${ port }`
}

export {
	getDynamicDomain,
	getDynamicDomainSocket
}
