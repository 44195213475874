import React from "react";
import {
	Box,
	Button,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Person as PersonIcon,
	Logout as LogoutIcon
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {authLogOut} from "../../../../states/profile";
import {Link} from "react-router-dom";

const Header = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleLogout = async () => {
		await dispatch(authLogOut())
	}

	return (
		<Box className={classes.root}>
			<Link to="/">
				<img src={require('../../../../assets/image/logo/logo-round.svg').default}/>
			</Link>

			<Box className={classes.divider}/>
			<Profile/>
			<ExitButton onClick={handleLogout}/>
		</Box>
	)
};
const Profile = () => {
	const classes = useStyles();
	const profile = useSelector((state) => state?.profile?.profile);

	return (
		<Box to="/profile" className={classes.profile} component={Link}>
			<Box className={classes.profileImage}>
				<PersonIcon sx={{ color: "#126DDE" }}/>
			</Box>
			<Typography
				className={classes.profileName}
				dangerouslySetInnerHTML={{
					__html: [profile?.profile?.first_name, profile?.profile?.last_name].filter((t) => !!t).join('<br>') || String(profile?.email || '').split('@')?.[0]
				}}
			/>
		</Box>
	)
};
const ExitButton = ({ onClick }) => {
	const classes = useStyles();
	return (
		<Button className={classes.exit} onClick={onClick}>
			Выйти
			<LogoutIcon className={classes.exitIcon}/>
		</Button>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		background: "#FFFFFFB2",
		borderRadius: 100,
		padding: 8,
		boxSizing: "border-box",
		height: 72
	},

	divider: {
		marginLeft: "auto",
		marginRight: 26,
		width: 1,
		height: 72,
		background: "#000000",
		opacity: 0.1
	},

	profile: {
		display: "flex",
		alignItems: "center",
		marginRight: 16,
		gap: 10,
		cursor: "pointer",
	},
	profileImage: {
		width: 36,
		height: 36,
		border: "1px solid #00000026",
		borderRadius: "100%",
		position: "relative",
		overflow: "hidden",
		background: "white",
		padding: 5,
		"& svg": {
			width: "100%",
			height: "100%"
		}
	},
	profileName: {
		fontSize: 13,
		lineHeight: 1.2,
		color: "#2C4058",
		textDecoration: "none"
	},

	exit: {
		display: "flex",
		alignItems: "center",
		gap: 16,
		border: "none",
		boxShadow: "none",
		opacity: 0.6,

		fontSize: 16,
		lineHeight: 1,
		color: "#2C4058",
		fontWeight: "400"
	},
	exitIcon: {
		fontSize: 20
	},
}));

export default Header
