import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Authorization as AuthorizationLayout
} from "../layouts";
import {
  Authorization as AuthorizationPage,
  Registration as RegistrationPage
} from "../pages";

const pages = [
  {
    path: '/',
    component: AuthorizationPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/registration',
    component: RegistrationPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
];

const AuthRoute = () => {
  return (
    <Switch>
      {
        pages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/"/>
    </Switch>
  );
};

export default AuthRoute;
