import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      borderRadius: 100,
      padding: "10px 32px",
      border: '0.5px solid rgba(0, 0, 0, 0.10)',
      boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',
      backdropFilter: 'blur(20px)',

      fontSize: 18,
      lineHeight: "24px",
      fontWeight: "500",
      textTransform: "initial"
    },
    contained: {
      background: palette.primary.main,
      "&:hover": {
        background: palette.primary.hover,
      }
    },
    outlined: {
      background: 'rgba(255, 255, 255, 0.40)',
      "&:hover": {
        background: "rgba(255, 255, 255, 0.70)",
        borderColor: "rgba(255, 255, 255, 0.70)"
      }
    }
  }
}
