import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      "& .MuiFormHelperText-root": {
        fontSize: 14,
        lineHeight: "19px",
        color: "#F5386A",
        justifyContent: "flex-start"
      }
    }
  }
}
