export default {
	styleOverrides: {
    input: {
      padding: '9px 14px!important',
    },
    inputRoot: {
      padding: 0,
      paddingRight: "0!important"
    },
    option: {
      fontSize: 14,
      lineHeight: "17px",
      color: "#2C4058",
    },
		noOptions: {
			padding: "8px 16px",
			fontSize: 14,
			lineHeight: "17px",
			color: "#2C4058",
		},
	}
}
