import React from "react";
import {
	Box,
	Grid,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Close as CloseIcon,
	AttachFile as AttachFileIcon
} from "@mui/icons-material";
import Dropzone from "react-dropzone";

const FilePicker = (props) => {
	const {
		name,
		value,
		message,
		onRemove,
		...otherProps
	} = props;
	const classes = useStyles();

	const handleChangeFiles = (files) => {
		props.onChange({
			target: {
				name,
				value: files
			}
		})
	}

	return (
		<>
			<Dropzone{...otherProps} onDrop={handleChangeFiles}>
				{({getRootProps, getInputProps}) => (
					<Box className={classes.dragDrop} {...getRootProps()}>
						<input {...getInputProps()} />
						<Typography
							className={classes.message}
						>{ message || 'Перетащите сюда несколько файлов или щелкните, чтобы выбрать файлы.'}</Typography>
					</Box>
				)}
			</Dropzone>

			<Box mt={2}/>
			<Grid container spacing={2} alignItems="flex-start">
				{value.map((t, i) => (
					<Grid item>
						<SelectedFile
							item={t}
							onRemove={onRemove.bind(null, i)}
						/>
					</Grid>
				))}
			</Grid>
		</>
	)
};
const SelectedFile = ({ item, onRemove }) => {
	const classes = useStyles();
	return (
		<Box className={classes.selectedFile}>
			<AttachFileIcon sx={{ fontSize: 16, color: "#126DDE" }}/>
			<Typography className={classes.selectedFileName}>{ item.name }</Typography>
			<Tooltip title="Удалить файл">
				<CloseIcon
					sx={{ fontSize: 16, color: "#126DDE", cursor: "pointer" }}
					onClick={onRemove}
				/>
			</Tooltip>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {},
	dragDrop: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		borderRadius: 12,
		background: "white",
		border: "1px solid #0000001A",
		padding: 15,
		minHeight: 240,
		cursor: "pointer",
	},
	message: {
		fontSize: 20,
		lineHeight: 1.2,
		color: "#99ACC3",
		textAlign: "center",
	},

	selectedFile: {
		display: "flex",
		alignItems: "center",
		background: "#126DDE1A",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		padding: "8px 16px",
		boxSizing: "border-box",
		borderRadius: 100,
		gap: 4
	},
	selectedFileName: {
		fontSize: 12,
		lineHeight: 1.2,
		color: "#126DDE",
		fontWeight: "500"
	}
}));

export default FilePicker
