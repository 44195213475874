import React from "react";
import {
	Box,
	Grid,
	Tooltip,
	IconButton,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon,
	Delete as DeleteIcon,
} from "@mui/icons-material";

const Header = (props) => {
	const {
		name,
		file,

		onChange,
		onRemove,
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography className={classes.title}>{ name }</Typography>
			<Grid container sx={{ flex: 0 }} wrap="nowrap">
				<Grid item>
					<Grid container spacing={2} alignItems="center" wrap="nowrap">
						<Grid item>
							<Tooltip title="Изменить название чата" arrow>
								<IconButton color="primary" onClick={onChange}>
									<EditIcon sx={{ fontSize: 24 }}/>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							{Boolean(file) && (
								<Typography >
									Работа ведется по файлу "{file?.name}"
								</Typography>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Tooltip title="Удалить чат" arrow>
						<IconButton color="error" onClick={onRemove}>
							<DeleteIcon sx={{ fontSize: 24 }}/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px 24px",
		boxSizing: "border-box",
		background: "white",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		borderRadius: 16,
		marginBottom: 10
},
	title: {
		fontSize: 20,
		lineHeight: 1.2,
		color: "#2C4058",
		fontWeight: "500"
	},
	remove: {},
}))

export default Header
