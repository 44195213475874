import React from "react";
import {
	Box,
	Grid,
	Portal,
	Tooltip,
	Backdrop,
	TextField,
	IconButton,
	CircularProgress,

	List,
	ListItemText,
	ListItemIcon,
	ListItemButton,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Send as SendIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {changeChatName, createChat, removeChat} from "../../states/chats";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {
	DialogChange,
	BackdropLoading
} from "../../pages/service/ChatId/components";
import DialogConfirmation from "../DialogConfirmation";

const ListChats = () => {
	const classes = useStyles();

	const chats = useSelector((state) => state.chats.chats);
	const history = useHistory();
	const dispatch = useDispatch();
	const routeMatch = useRouteMatch();

	const [nameNewChat, setNameNewChat] = React.useState("");
	const handleChangeNameNewChat = ({ target }) => {
		setNameNewChat(target?.value || "");
	};
	const handleKeyDown = async (event) => {
		if (event.key === "Enter") {
			await handleCreateNewChat();
		}
	};
	const handleCreateNewChat = async () => {
		const res = await dispatch(createChat(nameNewChat || `Chat ${ chats.length + 1 }`));
		setNameNewChat("");

		if (res?.id) {
			history.push(`/chat/${res?.id}`);
		}
	};

	const refDialogChange = React.createRef();
	const refDialogConfirmation = React.createRef();
	const [isBackdrop, setBackdrop] = React.useState(false);
	const handleChangeChat = async (chatId, body) => {
		if (!body) {
			refDialogChange.current.open({
				onSubmit: handleChangeChat.bind(this, chatId)
			})
			return
		}
		setBackdrop(true);
		refDialogChange.current.close();
		await dispatch(changeChatName(chatId, body));
		setBackdrop(false);
	};
	const handleRemoveChat = async (isConfirm, chatId) => {
		if (!isConfirm) {
			refDialogConfirmation.current.open({
				message: `Вы действительно хотите удалить чат?`,
				acceptButtonTitle: "Да, удалить",
				onSubmit: handleRemoveChat.bind(null, true, chatId)
			});
			return null
		}
		setBackdrop(true);
		await dispatch(removeChat(chatId));
		history.push('/');
		setBackdrop(false);
	};

	return (
		<Box
			className={classes.root}
		>
			<Box className={classes.header}>
				<Grid
					container
					spacing={1}
					wrap="nowrap"
				>
					<Grid item sx={{ flex: 1 }}>
						<TextField
							value={nameNewChat}
							fullWidth
							variant="outlined"
							placeholder={`Chat ${ chats.length + 1 }`}
							onKeyDown={handleKeyDown}
							onChange={handleChangeNameNewChat}
						/>
					</Grid>
					<Grid item>
						<Tooltip title="Создать новый чат" arrow>
							<IconButton
								color="primary"
								onClick={handleCreateNewChat}
							>
								<SendIcon sx={{ fontSize: 20 }}/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</Box>
			<Box className={classes.content}>
				<List>
					{chats.map((chat) => (
						<ChatItem
							key={`chat.id-${ chat.id }`}
							item={chat}
							selected={Boolean(routeMatch.path === '/chat/:id' && routeMatch.params?.id === chat.id)}

							onChange={handleChangeChat}
							onRemove={handleRemoveChat}
						/>
					))}
				</List>
			</Box>

			<BackdropLoading open={isBackdrop}/>
			<DialogChange ref={refDialogChange}/>
			<DialogConfirmation ref={refDialogConfirmation}/>
		</Box>
	)
};
const ChatItem = ({ item, selected, onChange, onRemove }) => {
	const classes = useStyles();

	const handleEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		onChange(item.id, null);
	};
	const handleRemove = (event) => {
		event.preventDefault();
		event.stopPropagation();
		onRemove(false, item.id);
	};

	return (
		<ListItemButton
			to={`/chat/${ item.id }`}
			component={Link}
			selected={selected}
			className={classes.listItemButton}
		>
			<ListItemText>
				{ item.chat_name }
			</ListItemText>
			<ListItemIcon>
				<Grid container spacing={1}>
					<Grid item>
						<Tooltip title="Изменить название чата" arrow>
							<IconButton color="primary" onClick={handleEdit}>
								<EditIcon sx={{ fontSize: 16 }}/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Удалить чат" arrow>
							<IconButton color="error" onClick={handleRemove}>
								<DeleteIcon sx={{ fontSize: 16 }}/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</ListItemIcon>
		</ListItemButton>
	)
};

const useStyles = makeStyles(() => ({
	root: {
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		borderRadius: 16,
		background: "white",
		border: "1px solid #0000001A",
		padding: "20px",
		boxSizing: "border-box"
	},
	header: {
		position: "relative",
		paddingBottom: 10,
		marginBottom: 10,
		"&:after": {
			content: "''",
			position: "absolute",
			left: -20, right: -20,
			bottom: -10,
			height: 1,
			background: "black",
			opacity: 0.1
		}
	},
	content: {
		"& .MuiList-root": {
			margin: "0 -20px",
			maxHeight: "calc(100vh - 206px)",
			overflow: "auto",
		},
		"& .MuiButtonBase-root": {
			padding: "8px 20px"
		},
		"& .MuiListItemText-root": {
			margin: 0
		},
		"& .MuiListItemText-primary": {
			fontSize: 16,
			lineHeight: "20px",
			color: "#2C4058"
		}
	},

	listItemButton: {
		"& .MuiListItemIcon-root": {
			opacity: 0,
		},
		"& .MuiButtonBase-root": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			padding: 0,
			width: 32, height: 32
		},
		"&:hover .MuiListItemIcon-root": {
			opacity: 1
		}
	}
}));

export default ListChats
