import { compose } from 'recompose';
import { connect } from 'react-redux';
import Profile from './Profile';
import {authGetMe} from "../../../states/profile";

export default compose(
  connect(
    state => ({
      profile: state.profile?.profile?.profile || {}
    }),
    dispatch => ({
      authGetMe: () => dispatch(authGetMe())
    }),
  ),
)(Profile);
