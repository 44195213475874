import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      "&.MuiTypography-formLabel": {
        display: "block",
        fontSize: 14,
        lineHeight: '16px',
        color: palette.primary.text,
        fontFamily: "Suisse Intl"
      }
    },

    h1: {},
    h2: {},
    h3: {},
    h4: {},
    h5: {},
    h6: {},
  }
}
