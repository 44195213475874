import React, {Component} from "react";
import {
	Grid
} from "@mui/material";
import {
	ListChats
} from "../../../components";
import {
	NoResult
} from "./components";

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {

		};
	}

	componentDidMount = () => {
	}

	render() {
		return (
			<Grid container spacing={4}>
				<Grid item xs={3}>
					<ListChats/>
				</Grid>
				<Grid item xs={9} sx={{ display: "flex", height: "calc(100vh - 92px)" }}>
					<NoResult
						hideMessage={true}
					/>
				</Grid>
			</Grid>
		);
	}
}

export default Home
