import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Root.js';
import "./assets/fonts/index.css";
import "./assets/theme/index.css";
import "react-notifications-component/dist/theme.css";
import 'react-image-lightbox/style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
