import agent from "../agent/agent";
import localStorage from "redux-persist/es/storage";
import {getChats, resetChats} from "./chats";

const GET_PROFILE = "profile/GET_PROFILE";

const initialState = {
  profile: null,
};

export function authLogin (body) {
  return async function (dispatch) {
    const res = await agent.post('/api/v1/auth/login', body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    if (res.error) {
      return res
    }

    await localStorage.setItem('access_token', res.access_token);
    await localStorage.setItem('refresh_token', res.refresh_token);

    agent.defaults.headers['Authorization'] = `Bearer ${ res.access_token }`;

    dispatch(authGetMe());
    dispatch(getChats())
  }
}
export function authGetMe () {
  return async function (dispatch) {
    const token = await localStorage.getItem('access_token');
    const isValid = await agent.get(`/api/v1/auth/token/valid?token=${ token }`).then((res) => {
      return res.data
    }).catch((err) => {
      return null
    });
    if (!isValid) {
      return null
    }

    const profile = await agent.get('/api/v1/accounts/current').then((res) => {
      return res.data
    }).catch((err) => {
      return null
    });
    if (!profile) {
      return null
    }

    dispatch({
      type: GET_PROFILE,
      profile: profile
    })
  }
}
export function authLogOut () {
  return async function (dispatch) {
    await agent.get('/api/v1/auth/logout');
    await dispatch(resetChats());
    await localStorage.removeItem('access_token');
    await localStorage.removeItem('refresh_token');
    delete agent.defaults.headers['Authorization'];
    dispatch({
      type: GET_PROFILE,
      profile: null
    })
  }
}

export default function GlobalState(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PROFILE: {
      return {
        ...state,
        profile: action.profile,
      }
    }

    default:
      return state;
  }
}
