import React from "react";
import {
	Box
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Header
} from "./components";

class Cabinet extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
		const {
			classes
		} = this.props;

		return (
			<Box className={`${ classes.root } background-pages`}>
				<Box className={classes.header}>
					<Header/>
				</Box>
				<Box className={classes.content}>
					{this.props.children}
				</Box>
			</Box>
		)
	}
}

const styles = {
	root: {
		padding: 10,
		paddingTop: 92,
		boxSizing: "border-box",
	},
	header: {
		position: "fixed",
		top: 10, left: 10, right: 10,
		zIndex: 20
	}
};
Cabinet = withStyles(styles)(Cabinet);

export default Cabinet
