import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      "& .MuiTableCell-root": {
        backgroundColor: "white",
        border: "none",

        fontSize: 14,
        lineHeight: "120%",
        color: palette.black,
        fontWeight: "400",

        "&:first-child": {
          borderRadius: "4px 0 0 4px"
        },
        "&:last-child": {
          borderRadius: "0 4px 4px 0"
        },
      },
      "& .MuiTableRow-root": {
        boxShadow: "0px 2px 8px 0px rgba(142, 150, 168, 0.24)"
      }
    }
  }
}
