import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      "& .MuiTableCell-root": {
        backgroundColor: "rgba(134, 152, 177, 0.3)",
        border: "none",

        fontSize: 14,
        lineHeight: "100%",
        color: "#8698B1",
        fontWeight: "400",

        "&:first-child": {
          borderRadius: "4px 0 0 4px"
        },
        "&:last-child": {
          borderRadius: "0 4px 4px 0"
        },
      }
    }
  }
}
