import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      "& .MuiButtonBase-root": {
        backgroundColor: "white",

        fontSize: 14,
        lineHeight: "120%",
        color: palette.black,

        "&:hover": {
          backgroundColor: "rgba(194, 207, 224, 0.2)"
        },
        "&.Mui-selected": {
          background: 'rgba(194, 207, 224, 0.6)',
          fontWeight: '500'
        }
      }
    }
  }
}
