import React from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  TextField,
  FormLabel,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Formik
} from "formik";
import {
  Notification,
  notificationTypes
} from "../../../common/Notification";
import * as Yup from "yup";
import {Link} from "react-router-dom";

class Authorization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: "",
        password: "",
      },
      isBackdrop: false,
    };
    this.refFormik = React.createRef();
  }

  changeForm = ({ target }) => {
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  };
  submitForm = async (values) => {
    await this.setState({ isBackdrop: true });
    const res = await this.props.authLogin(values);
    if (res?.error) {
      Notification({
        type: notificationTypes.error,
        message: res?.error?.data?.message || "Ошибка сервера"
      })
      await this.setState({ isBackdrop: false });
      return
    }
    console.log('res: ', res)
    console.log('values: ', values)
  };

  render () {
    const {
      classes
    } = this.props;
    const {
      form,
      isBackdrop
    } = this.state;

    return (
      <Box className={classes.root}>
        <Box className={classes.section}>
          <Typography className={classes.title}>Авторизация</Typography>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.submitForm}
          >{(params) => {
            const {
              values,
              errors,
              touched,
              handleSubmit
            } = params;

            return (
              <Grid
                container
                spacing={1}
              >
                <Grid item xs={12}>
                  <FormLabel sx={{ marginBottom: 1 }}>Ваш Email</FormLabel>
                  <TextField
                    value={values.email}
                    name="email"
                    variant="outlined"
                    placeholder="Введите Ваш Email адрес"
                    fullWidth={true}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel sx={{ marginBottom: 1 }}>Пароль</FormLabel>
                  <TextField
                    value={values.password}
                    name="password"
                    type="password"
                    variant="outlined"
                    placeholder="Пароль"
                    fullWidth={true}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={3}/>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleSubmit}
                  >Войти</Button>

                  <Box mt={1}/>
                  <Typography variant="subtitle1" textAlign="center">
                    Нет аккаунта? <Link to="/registration">Зарегистрироваться</Link>
                  </Typography>
                </Grid>
              </Grid>
            )
          }}</Formik>
        </Box>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Box>
    )
  }
};

const validationSchema = Yup.object().shape({
  // email: Yup.string().email("Некорректно введен Email адрес").required("Обязательно к заполнению"),
  password: Yup.string().required("Обязательно к заполнению"),
});

const styles = {
  root: {
    padding: "40px 0",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center"
  },
  section: {
    width: "100%",
    maxWidth: 520,
    padding: 32,
    boxSizing: "border-box",
    borderRadius: 16,
    background: "#F6F7F9",
    boxShadow: "-1px 5px 12px 0px #0F448A0D, -3px 21px 22px 0px #0F448A0A, -7px 48px 29px 0px #0F448A08, -13px 85px 35px 0px #0F448A03, -20px 134px 38px 0px #0F448A00",
  },
  title: {
    marginBottom: 24,

    fontSize: 28,
    lineHeight: "32px",
    color: "#005BCB",
    fontWeight: "500",
    textAlign: "center"
  },

};
Authorization = withStyles(styles)(Authorization);

export default Authorization
