// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ChatId from './ChatId';
import {changeChatName, getChats, removeChat} from "../../../states/chats";

export default compose(
  connect(
    state => ({
      chats: state.chats?.chats || [],
    }),
    dispatch => ({
      getChats: () => dispatch(getChats()),
      removeChat: (chatId) => dispatch(removeChat(chatId)),
      changeChatName: (chatId, body) => dispatch(changeChatName(chatId, body)),
    }),
  ),
)(ChatId);
